import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import JobListing from 'components/joblisting';

const Query = graphql`
  {
    allGreenhouseJob(filter: { gh_Id: { ne: 3427618 } }) {
      totalCount
      edges {
        node {
          title
          internal_job_id
          gh_Id
          location {
            name
          }
          offices {
            name
            gh_Id
            fields {
              slug
            }
          }
          departments {
            gh_Id
            name
            fields {
              slug
            }
          }
          fields {
            slug
          }
          metadata {
            name
          }
        }
      }
    }
    allGreenhouseDepartment {
      edges {
        node {
          name
          gh_Id
          jobs {
            title
          }
        }
      }
    }
    allGreenhouseOffice {
      edges {
        node {
          name
          gh_Id
        }
      }
    }
  }
`;

function DepartmentLiveJobs(props) {
  const {
    ghId,
    latestJobs,
    jobData,
    departmentTitle,
    showMoreButton,
    departments,
  } = props;
  return (
    <StaticQuery
      query={`${Query}`}
      render={data => (
        <JobListing
          data={data}
          tableLite={false}
          filtersOff={true}
          ghId={ghId}
          departmentTitle={departmentTitle}
          latestJobs={latestJobs ? latestJobs : false}
          jobData={jobData}
          showMoreButton={showMoreButton}
          departments={departments}
        />
      )}
    />
  );
}

DepartmentLiveJobs.propTypes = {
  ghId: PropTypes.number,
  latestJobs: PropTypes.bool,
  jobData: PropTypes.object,
  departmentTitle: PropTypes.string,
  showMoreButton: PropTypes.bool,
  departments: PropTypes.bool,
};

export default DepartmentLiveJobs;
