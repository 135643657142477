import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import BackgroundImage from 'gatsby-background-image';
import BtnLg from 'components/buttons/btnlg';
import styled from 'styled-components';
import { RichText } from 'prismic-reactjs';

export const Background = styled.div`
  background-size: contain;
  background-image: ${props => `url(${props.image})`};
`;

const styles = theme => ({
  hero: {
    minHeight: '800px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPositionY: 'top',
    backgroundPositionX: '100%',
    [theme.breakpoints.down('md')]: {
      minHeight: '600px',
      backgroundPositionX: 'calc(100% + 900px) !important',
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: 'auto',
    },
    '&:before, &:after': {
      [theme.breakpoints.down('sm')]: {
        backgroundImage: 'none !important',
      },
    },
  },
});

function FullWidthTextImage(props) {
  const { data, classes } = props;
  return (
    <Box bgcolor="background.lgrey">
      <Background className={classes.hero} Tag="section" image={data.image.url}>
        <Container>
          <Grid container>
            <Grid item xs={12} md={4}>
              <Box py={[4, 4, 4, 10]}>
                {data.title && (
                  <Typography component="h2" variant="h2" gutterBottom>
                    {data.title.text}
                  </Typography>
                )}
                {data.caption && (
                  <Typography component="h4" variant="h4" gutterBottom>
                    {data.caption.text}
                  </Typography>
                )}
                <Typography component="div">{data.body.text}</Typography>
                <Box mt={[2, 4]} display="flex" justifyContent="left">
                  <BtnLg
                    color="#0A9828"
                    hoverColor="#006945"
                    link="opportunities"
                    content="Get involved"
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={7}></Grid>
          </Grid>
        </Container>
      </Background>
    </Box>
  );
}

FullWidthTextImage.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.object,
};

export default withStyles(styles)(FullWidthTextImage);
