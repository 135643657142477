import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { RichText } from 'prismic-reactjs';
import { withStyles } from '@material-ui/styles';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';

import linkResolver from 'utils/linkResolver';
import htmlSerializer from 'utils/htmlSerializer';

export const styles = theme => ({
  playerWrapper: {
    position: 'relative',
    paddingTop: '56.25%',
    backgroundColor: theme.palette.background.default,
  },
  reactPlayer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
  },
  bg: {
    width: '100%',
    height: '100%',
  },
  playBtnWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2,
  },
  bgBox: {
    backgroundColor: theme.palette.background.green,
    position: 'absolute',
    height: 'calc(100% + 10rem)',
    top: '-5rem',
    clipPath: 'polygon(30% 0, 100% 30%, 70% 100%, 0 70%)',
    width: '80%',
    left: '10%',
  },
  whiteBg: {
    backgroundColor: theme.palette.background.default,
  },
  play: {
    background: 'transparent',
    margin: 0,
    padding: 0,
    border: 0,
    cursor: 'pointer',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    background: '#00000059',
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
  title: {
    color: theme.palette.text.green,
  },
  greyBg: {
    backgroundColor: '#F9F6F3',
  },
  playIcon: {
    // Update or add styles for the play icon as needed
    fontSize: '6rem',
    color: theme.palette.background.default,
    '&:hover': {
      color: theme.palette.background.mgrey,
    },
    cursor: 'pointer', // Ensure the icon looks clickable
  },
  // Add any additional styles you might need
});

function VideoBlock(props) {
  const { data, classes } = props;

  const openVideo = () => {
    const videoUrl = data.video_url.text; // Assuming video_url.text contains the URL
    if (videoUrl) window.open(videoUrl, '_blank');
  };

  return (
    <Box className={classes.greyBg} py={4}>
      <Container>
        {data.title && data.title.raw && (
          <Box mb={1}>
            <Typography
              component="div"
              variant="h3"
              className={classes.title}
              align="center"
            >
              <RichText render={data.title.raw} />
            </Typography>
          </Box>
        )}
        <Box
          component={Grid}
          container
          //justifyContent={justifyValue}
          alignItems="center"
        >
          {data.content && data.content.raw && (
            <Grid item xs={12} md={12}>
              <Typography align="center">
                <RichText
                  render={data.content.raw}
                  linkResolver={linkResolver}
                  htmlSerializer={htmlSerializer}
                />
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} md={12}>
            <Box position="relative" style={{ width: '100%' }} pt={2}>
              <img
                src={data.image.url}
                alt={data.image.alt}
                style={{ width: '100%' }}
              />
              {/* Play Button */}
              <Box
                className={classes.playBtnWrapper}
                onClick={openVideo} // Handle click to open the video
              >
                <PlayCircleOutlineIcon className={classes.playIcon} />
              </Box>
            </Box>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

VideoBlock.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(VideoBlock);
