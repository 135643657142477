import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { RichText } from 'prismic-reactjs';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  title: {
    color: theme.palette.text.green,
  },
  root: {
    boxShadow: '0 0 15px 0 rgba(67,134,179,.15)',
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
});

function FAQ(props) {
  const { data, fields, classes } = props;
  return (
    <Container maxWidth="md">
      <Box mb={5}>
        <Typography
          component="div"
          variant="h3"
          className={classes.title}
          align="center"
        >
          <RichText render={data.title} />
        </Typography>
      </Box>
      {fields.map((item, key) => {
        return (
          <Accordion key={key} className={classes.root}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                component="div"
                variant="body1"
                className={classes.heading}
              >
                <RichText render={item.question} />
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component="div" variant="body1">
                <RichText render={item.answer} />
              </Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Container>
  );
}

FAQ.propTypes = {
  data: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FAQ);
