import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { RichText } from 'prismic-reactjs';
import linkResolver from 'utils/linkResolver';
import htmlSerializer from 'utils/htmlSerializer';

import Modal from '@material-ui/core/Modal';

import { TitleCaptionText } from 'components/slices';

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    maxHeight: '90vh',
    overflow: 'scroll',
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: '80%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 4, 4),
    [theme.breakpoints.up('lg')]: {
      width: '60%',
    },
  },
  bgGrey: {
    backgroundColor: '#F9F6F3',
  },
}));

function QuartersGrid(props) {
  const classes = useStyles();
  const { data, fields } = props;
  console.log(data);
  const [open, setOpen] = useState([]);
  const [modalStyle] = React.useState(getModalStyle);

  const handleOpen = val => {
    setOpen(prevState => ({
      ...prevState,
      [val]: true,
    }));
  };

  const handleClose = () => {
    setOpen([]);
  };

  useEffect(() => {
    //console.log(open);
  }, [open]);

  return (
    <Box className={classes.bgGrey} py={6}>
      {data && (
        <Box mb={4}>
          <TitleCaptionText data={data} captionSml={true} />
        </Box>
      )}
      <Container>
        <Grid container spacing={4}>
          {fields.map((item, key) => {
            return (
              <Box component={Grid} item xs={6} md={4} lg={3} key={key}>
                {item.image && item.image.url && (
                  <Box align="center" mb={2}>
                    <img
                      src={item.image.url}
                      alt={item.image.alt ? item.image.alt : ''}
                    />
                  </Box>
                )}
                {item.content && item.content.raw && (
                  <Box align="center">
                    <RichText
                      render={item.content.raw}
                      linkResolver={linkResolver}
                      htmlSerializer={htmlSerializer}
                    />
                  </Box>
                )}
                {item.expanded_content?.html && (
                  <div style={{ textAlign: 'center' }}>
                    <Typography
                      component="button"
                      align="center"
                      style={{
                        fontWeight: 'bold',
                        background: 'none',
                        border: '0',
                        cursor: 'pointer',
                      }}
                      className={`modalTrigger-${key}`}
                      onClick={() => handleOpen(key)}
                    >
                      Read more
                    </Typography>

                    <Modal open={open[key]} onClose={handleClose}>
                      <div
                        style={modalStyle}
                        className={`${classes.paper} modal-content`}
                      >
                        <RichText
                          render={item.expanded_content.raw}
                          linkResolver={linkResolver}
                          htmlSerializer={htmlSerializer}
                        />
                      </div>
                    </Modal>
                  </div>
                )}
              </Box>
            );
          })}
        </Grid>
      </Container>
    </Box>
  );
}

QuartersGrid.propTypes = {
  fields: PropTypes.array.isRequired,
};

export default QuartersGrid;
