import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/styles';
import styled from 'styled-components';
import Map from 'components/map';
import { RichText } from 'prismic-reactjs';

export const Background = styled.div`
  background-size: cover;
  background-image: ${props => `url(${props.image})`};
`;

const styles = theme => ({
  hero: {
    height: '100%',
    minHeight: '300px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top left',
  },
  boxWrapper: {},
});

function Locations(props) {
  const { data, fields, classes } = props;
  return (
    <Box>
      <Container maxWidth="lg">
        {data.title && (
          <Box mb={2}>
            <Typography
              component="h2"
              variant="h2"
              align="left"
              className={classes.title}
            >
              {data.title.text}
            </Typography>
          </Box>
        )}
        {fields && (
          <Grid container>
            {fields.map((item, i) => (
              <React.Fragment key={i}>
                <Box
                  mb={1}
                  width="100%"
                  display="flex"
                  alignItems="left"
                  flexDirection="column"
                >
                  {item.title && (
                    <Typography component="h4" variant="h4" align="left">
                      {item.title.text}
                    </Typography>
                  )}
                  {item.address && (
                    <Typography component="div" align="left">
                      {item.address.text}
                    </Typography>
                  )}
                </Box>
                <Grid item xs={12}>
                  <Box className={classes.boxWrapper} mb={4}>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        style={{
                          paddingTop: '0px',
                          paddingBottom: '0px',
                          paddingRight: '10px',
                        }}
                      >
                        <Background
                          className={classes.hero}
                          image={item.image ? item.image.url : ''}
                        ></Background>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box style={{ height: '100%' }}>
                          {item.location.latitude &&
                            item.location.longitude && (
                              <Map
                                lat={item.location.latitude}
                                lng={item.location.longitude}
                              />
                            )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        )}
      </Container>
    </Box>
  );
}

Locations.propTypes = {
  data: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  classes: PropTypes.object,
};

export default withStyles(styles)(Locations);
