import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { RichText } from 'prismic-reactjs';
import linkResolver from 'utils/linkResolver';
import htmlSerializer from 'utils/htmlSerializer';
import { withStyles } from '@material-ui/styles';

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

const styles = theme => ({
  title: {
    color: theme.palette.text.green,
  },
});

function TextBlock(props) {
  const { data, classes } = props;
  return (
    <Container maxWidth="lg">
      <Grid container justify="center">
        {data?.content?.raw && (
          <Grid item xs={12} md={8}>
            <Typography component="div">
              <RichText
                render={data.content.raw}
                linkResolver={linkResolver}
                htmlSerializer={htmlSerializer}
              />
            </Typography>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

TextBlock.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TextBlock);
