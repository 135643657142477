import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles({
  gallery: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between', // This will space out the images evenly
    alignItems: 'center',
  },
  imageBox: {
    flex: '1 0 calc(14.28% - 10px)', // Adjust the percentage to fit 7 images in a row, subtracting the total margin from the width
    margin: '5px', // Gives a little space between each image
    height: 'auto', // Adjust the height as necessary
    alignItems: 'center',
    '& img': {
      maxWidth: '100%',
      height: 'auto', // Ensures the image scales correctly
    },
  },
});

function SliderSwiper(props) {
  const { data } = props;
  const classes = useStyles();

  return (
    <Container>
      <Box className={classes.gallery}>
        {data.map((item, key) => (
          <Box className={classes.imageBox} key={key}>
            <img src={item.image.url} alt={item.image.alt} />
          </Box>
        ))}
      </Box>
    </Container>
  );
}

SliderSwiper.propTypes = {
  data: PropTypes.array.isRequired,
};

export default SliderSwiper;
