import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import 'react-awesome-slider/dist/custom-animations/open-animation.css';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import { RichText } from 'prismic-reactjs';

const styles = theme => ({
  slider: {
    '&.awssld': {
      '--slider-height-percentage': '60%',
      '--slider-transition-duration': '700ms',
      '--organic-arrow-thickness': '4px',
      '--organic-arrow-border-radius': '0px',
      '--organic-arrow-height': '30px',
      '--organic-arrow-color': theme.palette.text.secondary,
      '--control-button-width': '10%',
      '--control-button-height': '25%',
      '--control-button-background': 'transparent',
      '--control-bullet-color': theme.palette.text.green,
      '--control-bullet-active-color': theme.palette.text.green,
      '--loader-bar-color': theme.palette.text.green,
      '--loader-bar-height': '6px',
    },
    '& .awssld__content': {
      '& > div': {
        width: '100%',
      },
    },
    '& .awssld__controls': {
      '& .awssld__next': {
        [theme.breakpoints.up('md')]: {
          right: '110px',
        },
      },
    },
  },
  ImageSlider: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      flex: '0 0 66.666667%',
      maxWidth: '66.666667%',
      clipPath: 'polygon(0 0, 100% 0, 85% 100%, 0% 100%)',
    },
  },
  ImageSliderContent: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      flex: '0 0 33.333333%',
      maxWidth: '33.333333%',
    },
  },
  bg: {
    backgroundColor: theme.palette.background.lmgrey,
  },
  title: {
    color: theme.palette.text.green,
  },
});

function ImageSlider(props) {
  const { data, fields, classes } = props;
  const getSlides = () => {
    return fields.map((item, key) => (
      <div key={key}>
        <Img
          fluid={item.imageSharp ? item.imageSharp.childImageSharp.fluid : {}}
          alt={item.image.alt}
        />
      </div>
    ));
  };
  return (
    <Box
      display="flex"
      flexDirection={{ xs: 'column', md: 'row' }}
      className={classes.bg}
    >
      <Box className={classes.ImageSlider}>
        <AwesomeSlider
          className={classes.slider}
          animation="openAnimation"
          bullets={false}
        >
          {getSlides()}
        </AwesomeSlider>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        className={classes.ImageSliderContent}
      >
        <Box p={[3, 3, 0, 0]}>
          <Box pr={[0, 0, 3, 6]}>
            <Typography component="div" variant="h3" className={classes.title}>
              <RichText render={data.title} />
            </Typography>
            <Typography component="div" variant="body1">
              <RichText render={data.caption} />
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

ImageSlider.propTypes = {
  data: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ImageSlider);
