import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Img from 'gatsby-image';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { RichText } from 'prismic-reactjs';

const styles = theme => ({
  link: {
    transition: 'all 0.4s ease-in-out',
    '&:hover': {
      color: theme.palette.text.green,
    },
  },
  accentLink: {
    display: 'inline-block',
    backgroundColor: theme.palette.text.pink,
    color: theme.palette.text.secondary,
    padding: '10px 20px',
    borderRadius: '5px',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: theme.palette.text.pinkHover,
      boxShadow: '0px 4px 8px 2px rgba(0,0,0,0.1)',
    },
  },
  title: {
    color: theme.palette.text.black,
  },
  subTitle: {
    fontWeight: '500',
  },
  img: {
    maxWidth: '100%',
  },
});

function ExternalLinksGrid(props) {
  const { data, fields, classes } = props;
  return (
    <Box>
      <Container maxWidth="lg">
        {data.title && (
          <Box
            component="div"
            display="flex"
            alignItems="center"
            justifyContent="center"
            mb={2}
          >
            <Typography
              className={classes.title}
              component="h2"
              variant="h2"
              align="center"
            >
              {data.title.text}
            </Typography>
          </Box>
        )}
        <Box mb={3}>
          {data.caption && (
            <Grid container justify="center">
              <Grid item md={8}>
                <Typography
                  variant="h6"
                  component="h6"
                  align="center"
                  color="textPrimary"
                  className={classes.subTitle}
                >
                  {data.caption.text}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Box>
        <Grid container justify="center" spacing={3}>
          {fields.map((item, i) => (
            <React.Fragment key={i}>
              <Grid item sm={6} md={3}>
                <Box mb={1}>
                  {item.image && item.image.url && (
                    <Box px={[5, 0, 0]}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`${item.link.url}`}
                      >
                        <img
                          className={classes.img}
                          src={item.image.url}
                          alt={item.image.alt}
                        />
                      </a>
                    </Box>
                  )}
                  {item.title && (
                    <Box my={1}>
                      <Typography
                        component="div"
                        variant="h4"
                        align="center"
                        style={{ fontWeight: '900' }}
                      >
                        <a
                          className={classes.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`${item.link.url}`}
                        >
                          {item.title.text}
                        </a>
                      </Typography>
                    </Box>
                  )}
                  {item.body && (
                    <Box>
                      <Typography component="div" align="center">
                        {item.body.text}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

ExternalLinksGrid.propTypes = {
  data: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  classes: PropTypes.object,
};

export default withStyles(styles)(ExternalLinksGrid);
