import React from 'react';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import { RichText } from 'prismic-reactjs';
import linkResolver from 'utils/linkResolver';
import htmlSerializer from 'utils/htmlSerializer';

const styles = theme => ({
  title: {
    color: theme.palette.text.green,
  },
});

function TitleCaptionText(props) {
  const { data, captionSml, classes } = props;
  return (
    <Container maxWidth="lg">
      {(data.title && data.title.text) ||
        (data.block_title && (
          <Box
            component="div"
            display="flex"
            alignItems="center"
            justifyContent="center"
            mb={2}
          >
            {data.title && data.title.text && (
              <Typography
                component="h2"
                variant="h2"
                align="center"
                className={classes.title}
              >
                {data.title.text}
              </Typography>
            )}
            {data.block_title && data.block_title.text && (
              <Typography
                component="h2"
                variant="h2"
                align="center"
                className={classes.title}
              >
                {data.block_title.text}
              </Typography>
            )}
          </Box>
        ))}
      {data.caption && data.caption.text && (
        <Grid container justify="center">
          <Grid item md={8}>
            {captionSml && (
              <Typography
                component="p"
                variant="body1"
                align="center"
                color="textPrimary"
              >
                {data.caption.text}
              </Typography>
            )}
            {!captionSml && (
              <Typography
                variant="h6"
                component="h6"
                align="center"
                color="textPrimary"
              >
                {data.caption.text}
              </Typography>
            )}
          </Grid>
        </Grid>
      )}
      {data.body1 && (
        <Grid container justify="center">
          <Grid item md={8}>
            <Typography component="div" align="center">
              <RichText
                render={data.body1.raw}
                linkResolver={linkResolver}
                htmlSerializer={htmlSerializer}
              />
            </Typography>
          </Grid>
        </Grid>
      )}
    </Container>
  );
}

TitleCaptionText.propTypes = {
  data: PropTypes.object.isRequired,
  captionSml: PropTypes.bool,
  classes: PropTypes.object,
};

export default withStyles(styles)(TitleCaptionText);
