import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import palette from 'styles/palette';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { RichText } from 'prismic-reactjs';
import htmlSerializer from 'utils/htmlSerializer';
import linkResolver from 'utils/linkResolver';

const styles = theme => ({
  boxWrapper: {
    height: '100%',
  },
  title: {
    color: palette.text.green,
  },
  img: {
    width: '100%',
  },
  greyBg: {
    backgroundColor: '#F9F6F3',
  },
});

function TwoColumn(props) {
  const { data, fields, classes } = props;
  return (
    <Box id="perks" name="perks" className={classes.greyBg} py={4}>
      <Container maxWidth="lg">
        {data?.title?.text && (
          <Box
            component="div"
            display="flex"
            alignItems="center"
            justifyContent="center"
            mb={2}
          >
            <Typography
              component="h2"
              variant="h2"
              align="center"
              className={classes.title}
            >
              {data.title.text}
            </Typography>
          </Box>
        )}
        {data?.caption?.text && (
          <Box mb={3}>
            <Grid container justify="center">
              <Grid item md={8}>
                <Typography
                  variant="h6"
                  component="h6"
                  align="center"
                  color="textPrimary"
                >
                  {data.caption.text}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )}
        {fields && (
          <Grid container spacing={4} justify="center">
            {fields.map((item, i) => (
              <React.Fragment key={i}>
                <Grid item xs={12} md={6}>
                  <Box className={classes.boxWrapper}>
                    {item?.image?.url && (
                      <Box>
                        <img
                          className={classes.img}
                          src={item.image.url}
                          alt={item.image.alt}
                        />
                      </Box>
                    )}
                    <Box p={[2, 4]}>
                      {item?.title?.text && (
                        <Typography
                          component="p"
                          variant="h4"
                          gutterBottom
                          style={{ fontWeight: '900' }}
                          align="center"
                        >
                          {item.title.text}
                        </Typography>
                      )}
                      {item?.body?.raw && (
                        <Typography component="div" align="center">
                          <RichText
                            render={item.body.raw}
                            linkResolver={linkResolver}
                            htmlSerializer={htmlSerializer}
                          />
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        )}
      </Container>
    </Box>
  );
}

TwoColumn.propTypes = {
  data: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  classes: PropTypes.object,
};

export default withStyles(styles)(TwoColumn);
