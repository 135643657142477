import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { RichText } from 'prismic-reactjs';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  title: {
    color: theme.palette.text.green,
  },
  img: {
    maxWidth: '100%',
  },
  stepIcon: {
    width: '15px',
    height: '15px',
    background: '#3bab28',
    boxSizing: 'content-box',
    borderLeft: '8px solid #ffffff',
    marginLeft: 'auto',
    borderRight: '8px solid #ffffff',
    marginRight: 'auto',
    borderRadius: '50%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  stepLine: {
    height: '5px',
    backgroundColor: '#E9F7FC',
  },
});

function Steps(props) {
  const { data, fields, classes } = props;
  return (
    <Container>
      <Box mb={2}>
        <Typography
          component="h3"
          variant="h3"
          className={classes.title}
          align="center"
        >
          {data.title.text}
        </Typography>
      </Box>
      {data.caption && (
        <Box>
          <Typography component="p" variant="body1" align="center">
            {data.caption.text}
          </Typography>
        </Box>
      )}
      {fields && fields.length > 0 && (
        <Box component={Grid} container mt={6}>
          {fields.map((field, key) => {
            return (
              <Grid item xs={12} md={4} lg key={key}>
                <Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    mb={3}
                    className={classes.iconStage}
                  >
                    <img
                      className={classes.img}
                      src={`${field.icon_image.url}&fit=clip&w=40&h=80`}
                      alt={field.step_title}
                    />
                  </Box>
                  <Box
                    position="relative"
                    bgcolor="primary.main"
                    className={classes.stepLine}
                  >
                    <Box className={classes.stepIcon} />
                  </Box>
                  <Box mt={3}>
                    <Typography component="h4" variant="h4" align="center">
                      {field.step_title.text}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography component="p" variant="body1" align="center">
                      {field.description.text}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            );
          })}
        </Box>
      )}
    </Container>
  );
}

Steps.propTypes = {
  data: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Steps);
