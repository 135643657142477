import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { RichText } from 'prismic-reactjs';
import linkResolver from 'utils/linkResolver';
import htmlSerializer from 'utils/htmlSerializer';

const styles = theme => ({
  title: {
    color: theme.palette.text.green,
  },
  img: {
    maxWidth: '100%',
  },
  greyBg: {
    backgroundColor: '#F9F6F3',
  },
});

function ImageLeftTextRight(props) {
  const { data, classes } = props;
  return (
    <Box component="div" className={classes.greyBg} py={4}>
      <Container maxWidth="lg">
        <Box component="div">
          <Grid container justify="space-between">
            <Grid item md={5} lg={6}>
              <img
                className={classes.img}
                src={data.image.url}
                alt={data.image.alt}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={5}>
              <Box>
                {data.title && (
                  <Typography
                    className={classes.title}
                    component="h2"
                    variant="h2"
                    gutterBottom
                  >
                    {data.title.text}
                  </Typography>
                )}
                {data.caption && (
                  <Typography component="h4" variant="h4" gutterBottom>
                    {data.caption.text}
                  </Typography>
                )}
                {data.body && (
                  <Typography component="div" gutterBottom>
                    <RichText
                      render={data.body.raw}
                      linkResolver={linkResolver}
                      htmlSerializer={htmlSerializer}
                    />
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default withStyles(styles)(ImageLeftTextRight);

ImageLeftTextRight.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.object,
};
