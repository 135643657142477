import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';

import BackgroundImage from 'gatsby-background-image';

import { RichText } from 'prismic-reactjs';

const styles = theme => ({
  wrapper: {
    backgroundColor: 'white',
    boxShadow: '0 0 15px 0 rgba(67,134,179,.15)',
    minHeight: '500px',
  },
  image: {
    flex: '0 0 100%',
    maxWidth: '100%',
    height: '350px',
    [theme.breakpoints.up('md')]: {
      height: 'auto',
      flex: '0 0 50%',
      maxWidth: '50%',
      clipPath: 'polygon(0 0, 100% 0, 85% 100%, 0% 100%)',
    },
  },
  bgImage: {
    height: '100%',
  },
  content: {
    flex: '0 0 100%',
    maxWidth: '100%',
    [theme.breakpoints.up('md')]: {
      flex: '0 0 50%',
      maxWidth: '50%',
    },
  },
  title: {
    color: theme.palette.text.green,
  },
});

function ImageCard(props) {
  const { data, classes } = props;
  return (
    <Container>
      <Box
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        className={classes.wrapper}
      >
        <Box className={classes.image}>
          <BackgroundImage
            className={classes.bgImage}
            Tag="section"
            fluid={data.imageSharp.childImageSharp.fluid}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          px={2}
          py={4}
          className={classes.content}
        >
          <Typography component="div" variant="h3" className={classes.title}>
            <RichText render={data.title} />
          </Typography>
          <Typography component="div" variant="body1">
            <RichText render={data.body} />
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}

ImageCard.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ImageCard);
