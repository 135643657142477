import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { RichText } from 'prismic-reactjs';
import linkResolver from 'utils/linkResolver';
import htmlSerializer from 'utils/htmlSerializer';

function ImageLeftContentRight(props) {
  const { data } = props;
  console.log(data);
  return (
    <Container>
      <Box
        component={Grid}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} md={5}>
          {data.image && data.image.url && (
            <img
              src={data.image.url}
              alt={data.image.alt ? data.image.alt : ''}
              className="w-100"
            />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <RichText
            render={data.content.raw}
            linkResolver={linkResolver}
            htmlSerializer={htmlSerializer}
          />
        </Grid>
      </Box>
    </Container>
  );
}

ImageLeftContentRight.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ImageLeftContentRight;
