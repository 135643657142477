import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';

export const Background = styled.div`
  position: relative;
  background-size: cover;
  background-image: ${props => `url(${props.image})`};
`;

const styles = {
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(46, 55, 73, 0.4)',
    zIndex: 0,
    transition: 'all 0.2s ease-in-out',
  },
  link: {
    display: 'block',
    '&:hover': {
      '& .overlay': {
        backgroundColor: 'rgba(46, 55, 73, 0.8)',
      },
    },
  },
  title: {
    zIndex: 2,
  },
};

function StackedImageLinks(props) {
  const { data, fields, classes } = props;
  return (
    <Box bgcolor="background.lgrey" py={5}>
      <Container maxWidth="lg">
        <Box mt={2}>
          <Grid container>
            <Grid item xs={12} md={7}>
              <Box pr={[0, 0, 5]} mb={[2, 2, 0]}>
                <Typography component="h2" variant="h2">
                  {data.title.text}
                </Typography>
                <Box mt={3} pr={[0, 4]}>
                  <Typography component="p" variant="body1">
                    {data.caption.text}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              {fields.map((item, i) => {
                return (
                  <React.Fragment key={i}>
                    <Box mb={1}>
                      <Background image={item.image ? item.image.url : ''}>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={item.link.url}
                          className={classes.link}
                        >
                          <Box className={`${classes.overlay} overlay`}></Box>
                          <Box py={4.5} display="flex" justifyContent="center">
                            <Typography
                              variant="h4"
                              color="textSecondary"
                              className={`${classes.title} title`}
                            >
                              {item.image.alt}
                            </Typography>
                          </Box>
                        </a>
                      </Background>
                    </Box>
                  </React.Fragment>
                );
              })}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

StackedImageLinks.propTypes = {
  data: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(StackedImageLinks);
