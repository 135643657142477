import React from 'react';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { RichText } from 'prismic-reactjs';
import htmlSerializer from 'utils/htmlSerializer';
import linkResolver from 'utils/linkResolver';

const styles = theme => ({
  title: {
    color: theme.palette.text.green,
  },
});

function TitleCaption(props) {
  const { data, classes } = props;
  return (
    <Container maxWidth="lg">
      {data.title && (
        <Box
          component="div"
          display="flex"
          alignItems="center"
          justifyContent="center"
          mb={2}
        >
          <Typography
            component="h2"
            variant="h2"
            align="center"
            className={classes.title}
          >
            {data.title.text}
          </Typography>
        </Box>
      )}
      {data.caption && (
        <Grid container justify="center">
          <Grid item md={8}>
            <Typography
              variant="h6"
              component="h6"
              align="center"
              color="textPrimary"
            >
              {data.caption.text}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Container>
  );
}

TitleCaption.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.object,
};

export default withStyles(styles)(TitleCaption);
